
import Vue from "vue";
export default Vue.extend({
  name: "filters",
  data: () => ({
    groups: [
      {
        name: "dimensions",
        caption: "Габариты",
        id: "0E7108AE-E5A4-4F94-86C4-CB0D7D78FF57",
        fields: [
          {
            id: "F8C6F0C5-3133-42F3-8AC0-C82D94FF2AA2",
            field: {
              name: "width",
              value: 0,
              type: "number",
              caption: "Ширина",
            },
            editor: {
              name: "number",
              attrs: {
                class: "col-md-6",
                hint: "До 200 символов",
              },
              config: {},
              validations: [
                {
                  validator: "max",
                  value: 200,
                  errorMessage:
                    "Поле не может содержать больще чем 200 символов",
                },
                {
                  validator: "min",
                  value: 2,
                  errorMessage: "Поле не может содержать меньше чем 2 символов",
                },
              ],
              watchers: [],
            },
          },
          {
            id: "01EB9639-8E70-4F38-92E0-B722FB4D5C67",
            field: {
              name: "height",
              value: 0,
              type: "number",
              caption: "Высота",
            },
            editor: {
              name: "number",
              attrs: {
                class: "col-md-6",
                hint: "До 200 символов",
              },
              config: {},
              validations: [
                {
                  validator: "max",
                  value: 200,
                  errorMessage:
                    "Поле не может содержать больше чем 200 символов",
                },
                {
                  validator: "min",
                  value: 2,
                  errorMessage: "Поле не может содержать меньше чем 2 символов",
                },
              ],
              watchers: [],
            },
          },
        ],
      },
      {
        name: "price",
        caption: "Стоиммость",
        id: "9E6DE18B-74A0-429B-9878-96418EC773C1",
        fields: [
          {
            id: "2618EF35-7989-4B5A-B845-95C47AA111C2",
            field: {
              name: "price",
              value: {
                min: 200,
                max: 30000,
              },
              type: "range",
              caption: "Цена",
            },
            editor: {
              name: "range",
              attrs: {},
              config: {},
              min: {
                value: 200,
                label: "Цена от",
              },
              max: {
                value: 300000,
                label: "Цена до",
              },
              validations: [],
              watchers: [],
            },
          },
        ],
      },
      {
        name: "additional",
        caption: "Дополниетльное",
        id: "79442A30-1F45-44A1-B1C6-C61ACDD5F0EB",
        fields: [
          {
            id: "BFDF6B70-A72C-4C7C-89D8-D6EAFB3C3ECD",
            field: {
              name: "additional",
              value: 0,
              type: "string",
              caption: "Согласие на доставку по адресу",
            },
            editor: {
              name: "string",
              attrs: {
                class: "col-md-12",
                hint: "Введите адрес",
              },
              config: {},
              validations: [],
              watchers: [],
            },
          },
        ],
      },
    ],
  }),
});
